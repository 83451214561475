import { render, staticRenderFns } from "./inter-bank-funds-transfer.vue?vue&type=template&id=6325f4ba&"
import script from "./inter-bank-funds-transfer.vue?vue&type=script&lang=ts&"
export * from "./inter-bank-funds-transfer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports